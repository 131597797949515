import { render, staticRenderFns } from "./SequenceDevice.vue?vue&type=template&id=15a06e19&scoped=true&"
import script from "./SequenceDevice.vue?vue&type=script&lang=js&"
export * from "./SequenceDevice.vue?vue&type=script&lang=js&"
import style0 from "./SequenceDevice.vue?vue&type=style&index=0&id=15a06e19&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a06e19",
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QIcon,QBtn,QTooltip});
