<template>
	<q-item class="handle cursor-pointer row items-center device-wrapper">
		<div class="col-10">
			<q-icon size="sm" class="q-mr-sm" :name="display_icon" />
			{{device_possition}}. {{device.label}}
		</div>
		<div class="col-2 justify-end text-right">
			<q-btn
				flat
				round
				color="dark"
				icon="o_drag_handle"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Order devices
				</q-tooltip>
			</q-btn>
		</div>
	</q-item>
</template>

<script>
export default {
	name: 'SequenceDevice',
	props:{
		device: Object,
		index: Number,
	},
	computed:{
		display_icon(){
			if(this.device.field_type === 'EMAIL'){
				return 'o_email'
			}else if(this.device.field_type === 'PHONE(VOICE)'){
				return 'o_call';
			}else if(this.device.field_type === 'PHONE(SMS)'){
				return 'o_textsms';
			}
		},
		device_possition(){
			return this.index + 1
		}
	},
}
</script>
<style lang="scss" scoped>
	.device-wrapper{
		border-bottom: 1px solid $grey-4;
	}
</style>