<template>
	<div class="router-view-wrapper" v-if="show_sequence">
		<div class="data-wrapper">
			<draggable
				v-model="scenario.devices"
				v-bind="dragOptions"
				handle=".handle"
				@start="drag = true"
				@end="drag = false"
				@sort="sort"
			>
				<q-list
					v-for="(device, index) in scenario.devices"
					:key="index"
					value="device"
				>
					<SequenceDevice
						:device="device"
						:index="index"
					/>
				</q-list>
			</draggable>
			<q-input
				class="padding"
				type="number"
				label="How many minutes interval should there be between each sequence step?"
				hint="The value must be in range between 2 and 9 minutes."
				v-model="sequence_time"
				onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
			/>
		</div>
	</div>
</template>

<script>
import SequenceDevice from "@/components/message_forms/sequence_tab/SequenceDevice.vue";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
	name: "MessageFormSequence",
	components: {
		SequenceDevice,
		draggable
	},
	data() {
		return {
			show_sequence: false
		};
	},
	computed: {
		...mapGetters(["scenario_type", "scenario", "logged_user"]),
		sequence_time: {
			get() {
				return this.$store.getters.sequence_time;
			},
			set(new_val) {
				this.$store.dispatch("updateSequenceTime", new_val);
			}
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		}
	},
	methods: {
		getMessage() {
			const errors = this.$store.getters.errors_per_tab['Sequence'];
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		reordered(event) {
			const reorderedItems = group.items.filter(
				item => event.detail.ids.map(Number).indexOf(item.id) >= 0
			);
			const newItems = group.items.filter(
				item => event.detail.ids.map(Number).indexOf(item.id) < 0
			);
			newItems.splice(event.detail.index, 0, ...reorderedItems);
			group.items = newItems;
		},
		sort() {
			for (let index in this.scenario.devices){
				this.scenario.devices[index].position = parseInt(index) + 1
			}
			this.$store.commit('resetScenarioDevices', this.scenario.devices)
		},
		setIndex(){
			let has_changed_devices = false
			for (let index in this.scenario.devices){
				if (!this.scenario.devices[index].position){
					this.scenario.devices[index].position = parseInt(index) + 1
				}else if (this.scenario.devices[index].position > this.scenario.devices.length){
					has_changed_devices = true
					break
				}
			}
			if (has_changed_devices){
				this.resetDeviceIndex()
			}
			this.$store.commit('resetScenarioDevices', this.scenario.devices)
		},
		resetDeviceIndex(){
			for (let index in this.scenario.devices){
				this.scenario.devices[index].position = parseInt(index) + 1
			}
		},
		sortDevices(){
			this.scenario.devices.sort((p1, p2) => (p1.position > p2.position) ? 1 : (p1.position < p2.position) ? -1 : 0);
		}
	},
	created() {
		this.show_sequence =
			this.scenario_type == "sequence" &&
			this.logged_user.customer_permission != "SENDER";
		this.setIndex()
		this.sortDevices()
		this.getMessage()
	}
};
</script>
